<template>
  <div class="row">
    <div class="col-12 col-lg-6 c">
      <div class="card">
        <div class="card-header">
          <h4>
            مجموعات الإعدادات
            <b-link to="settings/create" class="btn btn-primary">
              إنشاء مجموعة جديدة
            </b-link>
          </h4>
        </div>
        <div class="card-body">
          <img
            src="../assets/images/loading.svg"
            style="width: 60px; height: 60px; margin: 0px auto"
            alt=""
            v-if="loading"
          />
          <div class="list-group">
            <b-link
              v-for="g in groups"
              v-bind:key="g.code"
              type="button"
              ref="lists"
              :class="{
                'list-group-item list-group-item-action': true,
              }"
              :to="'/settings/edit/' + g.code"
            >
              {{ g.title }}
            </b-link>
          </div>
          <div class="col-12 text-center g">
            <strong class="text-danger">
              * قم بالضغط على المجموعة لتعديلها *
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
const axios = require("axios");
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      groups: [],
      group: {},
      loading: true,
    };
  },
  methods: {},
  created() {
    var _g = this;
    axios
      .post(localStorage.getItem("api") + "/teachers/groups", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.groups = r.data.response;
          _g.loading = false;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
};
</script>

<style>
</style>
